import React from 'react';

function Scroll() {
  return (
    <div className="scroll-teaser scrollFade">
      Scroll
    </div>
  );
}

export default Scroll;